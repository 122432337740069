import React, { Component } from "react";
import config from "../../data/config";
import { graphql } from "gatsby";
import _ from "lodash";
import Seo from "../components/Seo/Seo";
import { DiscussionEmbed } from "disqus-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faHome } from "@fortawesome/free-solid-svg-icons";
import { useHasScroll } from "has-scroll-hook";

import 'minireset.css';
import "../components/Layout/Layout.scss";

function HasScrooled({ children }) {
  const hasScroll = useHasScroll();
  return children(hasScroll);
}

export default class Post extends Component {
  render() {
    const { data } = this.props;
    const { fields } = data.markdownRemark;
    const { slug } = fields;
    const {
      title,
      image,
      tags,
      date,
      description,
    } = data.markdownRemark.frontmatter;
    const disqusConfig = {
      shortname: config.disqusName,
      config: { identifier: slug, title },
    };
    const { html } = data.markdownRemark;
    return (
      <section className="hero">
        <div
          className="hero-head header-post"
          role="banner"
          style={{
            backgroundImage: `url(/posts/${image})`,
          }}
        >
          <span className="btn-home icon is-medium has-text-white">
            <a href="/" title="Voltar para a home">
              <FontAwesomeIcon size="2x" style={{color: "#fff"}} icon={faHome} />
            </a>
          </span>
          <time date={date} className="date">
            {date}
          </time>
          <h1 className="title is-2">{title}</h1>
        </div>
        <div
          style={{
            backgroundColor: "#FFF",
            width: "100%",
            padding: "1.5rem",
            borderRadius: "0.50rem",
            margin: "10px 15px",
          }}
        >
          <Seo title={title} url={slug} description={description} article />
          <div className="hero-body">
            <article
              id="post"
              className="post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <article className="post-footer">
              {tags.map((tag) => (
                <span
                  style={{ margin: "0 2%" }}
                  className="tag is-link"
                  key={tag}
                  to={`/tag/${_.kebabCase(tag)}`}
                >
                  {tag}
                </span>
              ))}

              <h3>Comentários</h3>
              <div className="disqus-container">
                <DiscussionEmbed {...disqusConfig} />
              </div>
            </article>
          </div>
        </div>
        <HasScrooled>
          {(hasScroll) => (
            <div id="backtotop" className={`${hasScroll ? "visible" : ""}`}>
              <a href="#">
                <FontAwesomeIcon icon={faArrowUp} />
              </a>
            </div>
          )}
        </HasScrooled>
      </section>
    );
  }
}

export const pageQuery = graphql`
  query PostPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        tags
        description
        image
      }
      fields {
        slug
      }
    }
  }
`;
